import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import PrivacyBar from "../components/privacyBar"
import PropTypes from "prop-types"
import withLocation from "./withLocation"
import ContactForm from "./ContactForm"
import { event } from "jquery"
import animationImg from "../img/helpdesk-2.gif"
import { Sociallinks, Template, footer } from "./Config/variable"
import "../styles/style.scss"
const Footer = props => {
  const { pageType } = props

  const data = useStaticQuery(graphql`
    query FooterQuery {
      limitedCity: allCities(limit: 20) {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }

      allCities: allCities(sort: { fields: city, order: ASC }) {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
      datesBuild: allCurrentBuildDate {
        edges {
          node {
            currentDate
          }
        }
      }
    }
  `)

  const { utm_source } = props.search
  const { locality } = props.search

  // console.log(locality)
  const [floatingAction, setfloatingAction] = useState(false)
  const [modalView, setmodalView] = useState(false)
  const [intentmodalView, setintentmodalView] = useState(false)
  const [citymodalView, setcitymodalView] = useState(false)
  const [cities, setcities] = useState([""])
  const [spaceType, setspaceType] = useState("coworking-spaces")
  const floatingActionChange = () => {
    if (floatingAction === true) {
      setfloatingAction(false)
    } else {
      setfloatingAction(true)
    }
  }
  const closeModal = () => {
    if (modalView === true) {
      setmodalView(false)
      localStorage.setItem("newpopupclose", true)
      localStorage.setItem(
        "newpopupclosecount",
        Number(localStorage.getItem("newpopupclosecount")) + 1
      )
    } else {
      setmodalView(true)
      localStorage.setItem("newpopupclose", true)
    }
  }
  const closeCityModal = () => {
    setcitymodalView(false)
  }
  const changeSlug = slug => {
    setcitymodalView(false)
    localStorage.setItem("slug", slug)
    localStorage.setItem("resetLocation", false)
    navigate("/" + spaceType + "/" + slug + "/all")
  }
  useEffect(() => {
    var citySlugGet = window.location.pathname.split("/")
    if (citySlugGet.includes("all") && !localStorage.getItem("resetLocation")) {
      localStorage.setItem("slug", citySlugGet[2])
    }

    if (localStorage.getItem("slug") || pageType !== "city") {
      setcitymodalView(false)
    } else {
      setcitymodalView(true)
    }
  }, [])

  const handleScroll = () => {
    let scrollheight =
      ((window.scrollY + window.innerHeight) / document.body.scrollHeight) * 100

    if (scrollheight >= 25 && pageType == "city") {
      if (Number(localStorage.getItem("newpopupclosecount")) < 1) {
        setmodalView(true)
      } else {
        setmodalView(false)
      }
    }
  }
  const mouseOut = e => {
    let x = e.clientX
    let y = e.clientY
    console.log("y", y)
    if (y > 20) {
      setintentmodalView(true)
      setmodalView(false)
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }
  const intentcloseModal = () => {
    setintentmodalView(!intentmodalView)
    if (intentmodalView) {
      setmodalView(false)
    }
  }

  useEffect(() => {
    // if (
    //   localStorage.getItem("newpopupclosecount") > 1 &&
    //   localStorage.getItem("newpopupclose")
    // ) {
    //   setmodalView(false)
    // } else {
    //   setTimeout(() => {
    //     setmodalView(true)
    //   }, 6000)
    // }
    // if (localStorage.getItem("newpopupclose")) {
    //   setmodalView(false)
    // }
    if (locality) {
      localStorage.setItem("locality", locality)
      if (localStorage.getItem("resetLocation")) {
        setcitymodalView(true)
      } else {
        setcitymodalView(false)
      }
    } else {
      localStorage.removeItem("locality")
    }
    if (localStorage.getItem("spaceType")) {
      setspaceType(localStorage.getItem("spaceType"))
    }

    // if (utm_source) {
    //   setTimeout(() => {
    //     setmodalView(true)
    //   }, 5000)
    // } else {
    //   setmodalView(false)
    // }
  }, [])

  const topCity = [
    {
      slug: "bengaluru",
      cityname: "Bangalore",
    },
    {
      slug: "chennai",
      cityname: "Chennai",
    },
    {
      slug: "mumbai",
      cityname: "Mumbai",
    },
    {
      slug: "new-delhi",
      cityname: "New Delhi",
    },
    {
      slug: "noida",
      cityname: "Noida",
    },
    {
      slug: "gurugram",
      cityname: "Gurugram",
    },
    {
      slug: "hyderabad",
      cityname: "Hyderabad",
    },
    {
      slug: "pune",
      cityname: "Pune",
    },
    {
      slug: "kolkata",
      cityname: "Kolkata",
    },
  ]

  const CityList = ({ data }) => (
    <ul className="links row">
      {data.allCities.edges.map(city => {
        if (city.node.slug) {
          return (
            <li className="col-md-6 noleft">
              <Link to={"/" + city.node.slug + "/"}>
                {city.node.displayCity}
              </Link>
            </li>
          )
        }
      })}
    </ul>
  )

  const FamousLocality = ({ data }) =>
    data.map(city => {
      if (city.slug) {
        return (
          <div
            className="col-md-1 col-4 city-icons"
            onClick={() => {
              changeSlug(city.slug)
            }}
          >
            <p>
              <img
                src={
                  "https://assets.gofloaters.com/coworking/" +
                  city.slug +
                  ".svg"
                }
                alt={city.cityname}
              ></img>
              <br></br>
              {city.cityname}
            </p>
          </div>
        )
      }
    })
  const topCityList = [
    "Bangalore",
    "Chennai",
    "Mumbai",
    "New Delhi",
    "Noida",
    "Gurugram",
    "Hyderabad",
    "Pune",
    "Kolkata",
  ]

  const OtherLocality = ({ data }) =>
    data.allCities.edges.map(city => {
      if (city.node.slug) {
        if (!topCityList.includes(city.node.displayCity))
          return (
            <div
              className="col-md-2 col-4 city-icons-box"
              onClick={() => {
                changeSlug(city.node.slug)
              }}
            >
              <p>{city.node.displayCity}</p>
            </div>
          )
      }
    })
  const CoworkingCityList = ({ data }) =>
    data.limitedCity.edges.map(city => {
      if (city.node.slug) {
        return (
          <span>
            <Link to={"/coworking-spaces/" + city.node.slug + "/all"}>
              Coworking space in {city.node.displayCity}
            </Link>{" "}
            ･{" "}
          </span>
        )
      }
    })
  const MeetingCityList = ({ data }) =>
    data.limitedCity.edges.map(city => {
      if (city.node.slug) {
        return (
          <span>
            <Link to={"/meeting-spaces/" + city.node.slug + "/all"}>
              Meeting space in {city.node.displayCity}
            </Link>{" "}
            ･{" "}
          </span>
        )
      }
    })
  const OfficeCityList = ({ data }) =>
    data.limitedCity.edges.map(city => {
      if (city.node.slug) {
        return (
          <span>
            <Link to={"/office-spaces/" + city.node.slug + "/all"}>
              Office space in {city.node.displayCity}
            </Link>{" "}
            ･{" "}
          </span>
        )
      }
    })
  return (
    <footer>
      <div className="overlayIntent pt-4" onMouseMove={mouseOut}></div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <br></br>
            <a href="https://www.iksana.work/">
              <img
                data-src={Template.spacePartnerLogo}
                className="lazyload"
                width="160"
                alt="GoFloaters"
              ></img>
            </a>
            <br />
            <br />
            {/* <p style={{ fontSize: 19 }}>
              <b>GoFloaters</b> is a platform that helps you find work and
              meeting spaces that can be booked instantly.
            </p>
            <p style={{ fontSize: 19 }}>
              A unit of <b>SMBSure Business Solutions Private Limited</b> <br />{" "}
              A6, Industrial Estate, Mogappair West, Chennai 600037, Tamil Nadu,
              India
            </p> */}
            <br></br>
            <br></br> <br></br>
          </div>
          <div className="col-md-4 text-left">
            <b style={{ fontSize: footer.font }}>Quick Links</b>

            <div className="container">
              <ul className="links row">
                <li className="col-md-6 noleft">
                  <a href="https://www.iksana.work/"
                    target="blank"
                  >
                    About us
                  </a>
                </li>
                <li className="col-md-6 noleft">
                  <Link
                    to="https://www.iksana.work/contact "
                    target="blank"
                  >
                    Contact Us
                  </Link>
                </li>
                <li className="col-md-6 noleft">
                  <Link to="/faqs/"> FAQs </Link>
                </li>

                <li className="col-md-6 noleft">
                  <Link to="/privacy-policy/">Privacy Policy</Link>
                </li>
                <li className="col-md-6 noleft">
                  <Link to="/terms-and-conditions/">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>

          {/* <div className="col-md-4 text-left">
            <b style={{ fontSize: 18 }}>Cities</b>
            <div className="container">
              <StaticQuery
                query={graphql`
                  query {
                    allCities {
                      edges {
                        node {
                          city
                          cityPriority
                          displayCity
                          slug
                        }
                      }
                    }
                  }
                `}
                render={data => <CityList data={data} {...props} />}
              />
            </div>
          </div> */}

          <div className="col-md-4">
            <b style={{ fontSize: footer.font }} className="contact-us-p">
              Contact Us
            </b>
            <ul className="email">
              <li>
                Email: <a href={Template.email}>connect@iksana.work</a>
              </li>
            </ul>
            <ul className="sociallinks">
              <li>
                <a
                  href={Sociallinks.faceBook}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="https://static.wixstatic.com/media/9d5ddd_be6f03a6b931406bba949ce8d304961b~mv2.png/v1/fill/w_47,h_47,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/facebook.png"
                    width="30"
                    height="30"
                    alt="GoFloaters Facebook"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href={Sociallinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="https://static.wixstatic.com/media/9d5ddd_f3bc0a4add3b4a6ba4c2161c39e67f74~mv2.png/v1/fill/w_47,h_47,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/instagram.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters Instagram"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href={Sociallinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <img
                    src="https://static.wixstatic.com/media/9d5ddd_95fa5d06e90748808bfca3f28c19c3ba~mv2.png/v1/fill/w_47,h_47,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/linkedin.png"
                    width="30"
                    height="30"
                    className="lazyload"
                    alt="GoFloaters linkedin"
                  ></img>
                </a>
              </li>
              <li>
                <a
                  href={Sociallinks.twitter}
                  rel="noopener noreferrer nofollow"
                  target="_blank"
                >
                  <img
                    src="https://static.wixstatic.com/media/9d5ddd_dbfd33aaed144ef6a535f7e05d04c145~mv2.png/v1/fill/w_47,h_47,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/twitter.png"
                    className="lazyload"
                    width="30"
                    height="30"
                    alt="GoFloaters Twitter"
                  ></img>
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-4 mb-3 gofloaters">
            <b style={{ fontSize: footer.font }} className="contact-us-p">
              Powered By
            </b>
            <li>
              <a
                href="https://gofloaters.com/"
                target="_blank"
                rel="noopener noreferrer nofollow"
              >
                <img data-src="https://assets.gofloaters.com/logo.png" class=" ls-is-cached lazyloaded" width="230" height="65" alt="GoFloaters" src="https://assets.gofloaters.com/logo.png" />
              </a>
            </li>
          </div>
        </div>
      </div>
      {citymodalView ? (
        <div
          className="modal fade show"
          id="selectCity"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="selectCity"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog " role="document">
            <div className="modal-content" style={{ background: "#fff" }}>
              <div className="modal-body" style={{ padding: 15 }}>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeCityModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h2
                  style={{
                    fontSize: footer.PopularCities.fontSize,
                    fontWeight: footer.PopularCities.fontweight,
                  }}
                >
                  Popular Cities
                </h2>
                <div className="container">
                  <div className="row">
                    <FamousLocality data={topCity} />
                  </div>
                </div>

                <hr></hr>
                <h2 style={{ fontSize: "1.8em", fontWeight: "bold" }}>
                  Other Cities
                </h2>
                <div className="container">
                  <div className="row">
                    <OtherLocality data={data} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {modalView ? (
        <div
          className="modal fade show"
          id="weAreClosed"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="weAreClosed"
          aria-hidden="true"
          style={{ display: "block" }}
        >
          <div className="modal-dialog announcement" role="document">
            <div
              className="modal-content"
              style={{ background: "#fff", textAlign: "center" }}
            >
              <div className="modal-header">
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={closeModal}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body" style={{ padding: 15 }}>
                <div className="container">
                  <div className="row  ">
                    {/* <div className="col-md-3">
                      <img
                        src="https://assets.gofloaters.com/winner-1.png"
                        className="img-responsive"
                      ></img>
                    </div> */}
                    <div className="col-md-12 text-center">
                      <img src={animationImg} style={{ width: "120px" }}></img>
                      <h4 style={{ color: "#303030" }}>
                        <b>Not found out a space yet? </b>
                        <br />
                        Let us help you! <br />
                        <br />
                      </h4>
                      <a
                        style={{
                          color: "#303030",
                          background: "#f9d470",
                          padding: "15px 25px",
                          borderRadius: "20px",
                          fontSize: "16px",
                          fontWeight: "bold",
                        }}
                        href="#"
                        onClick={() => {
                          localStorage.setItem(
                            "newpopupclosecount",
                            Number(localStorage.getItem("newpopupclosecount")) +
                            1
                          )
                          setmodalView(false)
                          window.$zoho.salesiq.floatwindow.visible("show")
                        }}
                      >
                        Chat Now
                      </a>
                    </div>
                  </div>
                </div>

                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {intentmodalView
        ? ""
        : // <div
        //   className="modal fade show"
        //   id="intentClosed"
        //   tabIndex="-1"
        //   role="dialog"
        //   aria-labelledby="intentClosed"
        //   aria-hidden="true"
        //   style={{ display: "block" }}
        // >
        //   <div className="modal-dialog announcement" role="document">
        //     <div
        //       className="modal-content"
        //       style={{ background: "#fff", textAlign: "center" }}
        //     >
        //       <div className="modal-header">
        //         <button
        //           type="button"
        //           className="close"
        //           data-dismiss="modal"
        //           aria-label="Close"
        //           onClick={intentcloseModal}
        //         >
        //           <span aria-hidden="true">&times;</span>
        //         </button>
        //       </div>
        //       <div className="modal-body" style={{ padding: 15 }}>
        //         <div className="container">
        //           <div className="row  ">
        //             {/* <div className="col-md-3">
        //               <img
        //                 src="https://assets.gofloaters.com/winner-1.png"
        //                 className="img-responsive"
        //               ></img>
        //             </div> */}
        //             <div className="col-md-12"

        //               <h4 style={{ color: "#303030" }}>
        //                 <br />
        //                 <b>Oops! Don't miss that offer we have for you. </b>
        //                 <br />

        //                 Book a space now with discounts<br />
        //                 <br />

        //               </h4>
        //               <a
        //                 style={{
        //                   color: "#303030",
        //                   background: "#f9d470",
        //                   padding: "15px 25px",
        //                   borderRadius: "20px",
        //                   fontSize: "16px",
        //                   fontWeight: "bold",
        //                 }}
        //                 href="#" onClick={()=> { setmodalView(false); setintentmodalView(false);  window.$zoho.salesiq.floatwindow.visible("show") }}>
        //                 Chat Now
        //               </a>
        //             </div>

        //           </div>
        //         </div>

        //         <br />
        //         <br />

        //       </div>
        //     </div>
        //   </div>
        // </div>
        ""}

      {/* <div className="container mb-5">
        <div className="row">
          <div className="col-md-12">
            <hr></hr>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <p>
              <b
                className="contact-us-p"
                style={{ fontSize: "18px", textAlign: "left" }}
              >
                Coworking Day Pass in top cities
              </b>
            </p>

            <CoworkingCityList data={data} />
            <br />
            <br />
          </div>
          <div className="col-md-12">
            <p>
              <b
                className="contact-us-p"
                style={{ fontSize: "18px", textAlign: "left" }}
              >
                Meeting Rooms in top cities
              </b>
            </p>
            <MeetingCityList data={data} />
            <br />
            <br />
          </div>
          <div className="col-md-12">
            <p>
              <b
                className="contact-us-p"
                style={{ fontSize: "18px", textAlign: "left" }}
              >
                Office Spaces in top cities
              </b>
            </p>{" "}
            <OfficeCityList data={data} />
            <br />
            <br />
          </div>
        </div>
      </div> */}
      <div className="copyright mt-5 text-dark">
        <p>
          &copy; 2024 Iksana Workspaces
          <span className="Gofloaters mt-5 text-dark">
            @powered by GoFloaters
          </span>
        </p>
      </div>
      <PrivacyBar />
    </footer>
  )
}

Footer.propTypes = {
  props: PropTypes.object,
}

export default withLocation(Footer)
